export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/animate-a-bell-curve": [3],
		"/animated-bar-chart": [4],
		"/basics/bar-chart": [5],
		"/basics/basic-shapes": [6],
		"/basics/density-plot": [7],
		"/basics/donut-chart": [8],
		"/basics/drawing-svg": [9],
		"/basics/dual-axis": [10],
		"/basics/line-chart": [11],
		"/basics/line-gradient": [12],
		"/basics/scatter-chart-2": [14],
		"/basics/scatter-chart": [13],
		"/basics/stacked-bar-chart": [15],
		"/basics/svelte-5-d3-example": [16],
		"/beeswarm-chart": [17],
		"/comingsoon": [18],
		"/creating-data": [19],
		"/dna-gel": [20],
		"/du-bois-05": [21],
		"/maps/svelte-threejs-example": [22],
		"/maps/us-heatmap": [23],
		"/us-heatmap": [24]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';